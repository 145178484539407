<template>
    <v-layout column justify-space-around fill-height>
        <v-flex>
            <h1 class="text-xs-center headline pa-3">Choose your provider</h1>
        </v-flex>
        <v-flex>
            <v-list>
                <template v-for="(provider, index) in providers">
                    <v-list-tile @click="redirectSaml(provider)" class="provider">
                        <v-list-tile-content>
                            <v-list-tile-title
                                :id="'provider-'+provider.id"
                                class="text-xs-center">
                            {{ provider.name }}
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </template>
            </v-list>
        </v-flex>
    </v-layout>
</template>

<script>
    import loginContext from '../../mixins/loginContext';

    export default {
        name: 'saml-chooser',

        mixins: [loginContext],
        props: {
            providers: {
              type: Object,
              required: true
            },
            username: {
              type: String,
              required: true
            }
        },

        methods: {
            redirectSaml(provider) {
                const samlParams = new URLSearchParams();
                const samlUrl = new URL('/sso/saml2/authn', window.location.href);

                samlParams.set('id', provider.id);
                samlParams.set('returnUrl', this.loginContext.returnUrl);
                samlParams.set('usernameHint', this.username);
                samlUrl.search = samlParams;

                window.location = samlUrl;
            }
        }
    }
</script>
