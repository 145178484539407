<template>
    <v-flex column>
        <h1 class="text-xs-center headline py-3">Log in to your account.</h1>
        <v-form @submit.prevent="checkUsername">
            <v-text-field id="username"
                          label="Username"
                          prepend-icon="account_circle"
                          v-model="username"
                          autofocus
                          :error-messages="validation"
                          @mousedown="validation=[]" />
            <v-layout justify-center>
                <v-flex xs8>
                    <v-btn id="continueBtn"
                           type="submit"
                           block
                           class="font-weight-bold">
                        Continue
                    </v-btn>
                </v-flex>
            </v-layout>
            <div v-if="loginContext.allowSignUp" class="text-xs-center pt-4">
                <router-link id="signUpLink" :to="{name: 'signup'}">Sign up as new user</router-link>
            </div>
        </v-form>
    </v-flex>
</template>

<script>
    import loginContext from '../../mixins/loginContext';

    export default {
        name: 'login-username',

        mixins: [loginContext],

        data() {
            return {
                username: '',
                validation: []
            }
        },

        methods: {
            handleAuthProviderCheck(loginMethod) {
                // currently we have to look for saml and password until we do nascar
                const loginType = (loginMethod != null ? loginMethod.type : null);

                if (loginType === "import") {
                    window.location = new URL(loginMethod.redirectUri);
                }
                else if (loginType === "password") {
                    this.$router.push({ name: 'login-password', params: { selectedUsername: this.username } });
                }
                else if (loginType === "saml") {
                    if (loginMethod.providers.length === 1) {
                      const samlParams = new URLSearchParams();
                      const samlUrl = new URL('/sso/saml2/authn', window.location.href);

                      samlParams.set('id', loginMethod.providers[0].id);
                      samlParams.set('returnUrl', this.loginContext.returnUrl);
                      samlParams.set('usernameHint', this.username);
                      samlUrl.search = samlParams;

                      window.location = samlUrl;
                    }
                  this.$router.push({ name: 'saml-chooser', params: { providers: loginMethod.providers, username: this.username } });
                }
                else {
                    this.validation = ['Unable to find user.'];
                }
            },

            checkUsername() {
                if (this.username) {
                    this.$identityApi.getLoginMethods.post(
                        {
                            payload: { username: this.username, returnUrl: this.loginContext.returnUrl }
                        })
                        .then(request => this.handleAuthProviderCheck(request.data))
                        .catch(() => {
                            this.$emit('notify', {
                                text: "An error occurred. Please try again or contact your administrator if the error persists.",
                                type: 'error',
                                timeout: 0
                            });
                        });
                } else {
                    this.validation = ['Enter your username'];
                }
            }
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.validation = []
            });
        }
    }
</script>
