var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { column: "" } },
    [
      _c(
        "v-card",
        { staticClass: "logoutCard", attrs: { elevation: "24" } },
        [
          _c("v-progress-linear", {
            attrs: {
              rounded: "",
              height: "6",
              active: _vm.spLoggingOut,
              indeterminate: _vm.spLoggingOut
            }
          }),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              attrs: {
                id: "errorMessage",
                dismissible: "",
                type: "error",
                transition: "slide-x-reverse-transition"
              },
              model: {
                value: _vm.error,
                callback: function($$v) {
                  _vm.error = $$v
                },
                expression: "error"
              }
            },
            [_vm._v("\n            " + _vm._s(_vm.errorMessage) + "\n        ")]
          ),
          _vm._v(" "),
          _vm.loggedOut && _vm.spLoggingOut
            ? _c(
                "div",
                { staticClass: "returnMessage" },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "text-xs-center",
                      attrs: { id: "loggingOutMessage" }
                    },
                    [_vm._v("We are logging you out...")]
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "text-xs-center" }, [
                    _vm._v("Please don't close this window.")
                  ]),
                  _vm._v(" "),
                  _vm.signOutIframeUrl
                    ? _c("iframe", {
                        attrs: { hidden: "hidden", src: _vm.signOutIframeUrl }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm.loggedOut && !_vm.spLoggingOut
            ? _c(
                "div",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "text-xs-center",
                      attrs: { id: "logoutConfirmationMessage" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.samlLogoutUrl
                              ? "Redirecting you to logout of your organziation"
                              : "You are now logged out."
                          )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.samlLogoutUrl
                    ? _c("v-card-text", { staticClass: "text-xs-center" }, [
                        _vm._v(
                          "\n              If you are not automatically redirected please click "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              id: "samlLogoutUrl",
                              href: _vm.samlLogoutUrl
                            }
                          },
                          [_vm._v("here")]
                        ),
                        _vm._v(".\n            ")
                      ])
                    : _vm.postLogoutRedirectUri
                    ? _c("v-card-text", { staticClass: "text-xs-center" }, [
                        _vm._v("\n            Click "),
                        _c(
                          "a",
                          {
                            attrs: {
                              id: "postLogoutRedirectUri",
                              href: _vm.postLogoutRedirectUri
                            }
                          },
                          [_vm._v("here")]
                        ),
                        _vm._v(" to return to the\n            "),
                        _c("em", [_vm._v(_vm._s(_vm.clientName))]),
                        _vm._v(" application.\n          ")
                      ])
                    : _c("v-card-text", { staticClass: "text-xs-center" }, [
                        _vm._v("You may now close the window.")
                      ])
                ],
                1
              )
            : _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.logout($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-xs-center mt-2 mb-3" },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-card-title",
                            { attrs: { id: "logoutMessage" } },
                            [_vm._v("Ready to log out?")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs8: "" } },
                        [
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: {
                                    block: "",
                                    id: "signOut",
                                    type: "submit"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Yes\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }