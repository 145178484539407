<template>
    <v-flex column>
        <h1 class="text-xs-center title py-3">
            Welcome <span class="given-name" v-if="givenName">{{ givenName }}</span>
        </h1>
        <v-layout justify-center>
            <v-btn id="backButton" round @click="back">
                <v-icon>arrow_back</v-icon>
                <span id="selectedUsername" class="pl-3">{{ selectedUsername }}</span>
            </v-btn>

        </v-layout>
        <v-form @submit.prevent="login">
            <v-alert id="errorMessage" v-model="error"
                     dismissible
                     type="error"
                     transition="slide-x-reverse-transition">
                {{ errorMessage }}
            </v-alert>
            <v-text-field id="password"
                          label="Enter password"
                          ref="password"
                          :append-icon="showMask ? 'visibility_off' : 'visibility'"
                          :type="showMask ? 'password' : 'text'"
                          name="Password"
                          :error-messages="validation"
                          v-model="password"
                          prepend-icon="lock"
                          class="mt-4"
                          autofocus
                          @mousedown="validation=[]"
                          @click:append="showMask = !showMask" />

            <v-layout align-center justify-space-between>
                <v-flex xs6>
                    <v-checkbox id="rememberMe"
                                label="Remember Me"
                                name="RememberMe"
                                v-model="rememberMe" />
                </v-flex>

                <v-flex xs6 class="text-xs-right">
                    <router-link id="forgotPasswordLink"
                                 :to="{name: 'password-forgot', params: { initialUsername: this.selectedUsername } }">
                        Forgot your password?
                    </router-link>
                </v-flex>
            </v-layout>

            <v-layout justify-center>
                <v-flex xs8>
                    <v-btn id="signIn" type="submit" block>Sign In</v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-flex>
</template>

<script>
    import loginContext from '../../mixins/loginContext';
    import knownIdentities from '../../mixins/knownIdentities';

    export default {
        name: 'login-password',

        props: ['selectedUsername', 'selectedIndex'],

        mixins: [loginContext, knownIdentities],

        data() {
            return {
                rememberMe: false,
                showMask: true,
                password: '',
                error: false,
                errorMessage: '',
                validation: []
            }
        },

        computed: {
            username() {
                return this.selectedIndex == undefined ? this.selectedUsername : this.identities[this.selectedIndex].username;
            },

            givenName() {
                return this.selectedIndex == undefined ? undefined : this.identities[this.selectedIndex].givenName;
            }
        },

        methods: {
            login() {
                if (this.password) {
                    this.$identityApi.login.post({
                        payload:
                        {
                            Username: this.selectedUsername,
                            Password: this.password,
                            RememberMe: this.rememberMe,
                            AccountId: this.loginContext.accountId,
                            ReturnUrl: this.loginContext.returnUrl
                        }
                    })
                        .catch(this.failure)
                        .then(this.success);
                } else {
                    this.validation = ['Please enter your password'];
                }
            },

            success(response) {
                var newIdentity = {
                    username: response.data.username,
                    givenName: response.data.givenName,
                    familyName: response.data.familyName
                };

                this.upsertIdentityToKnownIdentities(newIdentity);

                let redirectUrl = this.getRedirectUrl(response.data.returnUrl);
                if (redirectUrl) {
                    window.location = redirectUrl;
                }
            },

            failure(error) {
                const errors = error.response.data.errors;
                // we only should ever get 1 error here. this just reduces the array to a string
                this.errorMessage = errors.map(e => e.message).join(' ');

                if (errors.map(e => e.code).includes('Login_User_NotActivated')) {
                    this.$router.push({ name: 'email-resend', params: { initialUsername: this.selectedUsername } });
                    this.password = '';
                } else {
                    this.error = true;
                    this.password = '';
                }
            },

            back() {
                this.$router.back();
            },

            getRedirectUrl(returnUrl) {
                if (this.$parent.returnRoute != "") {
                    return window.location.origin
                        + this.$router.resolve({ name: this.$parent.returnRoute }).href
                        + '?returnUrl=' + encodeURIComponent(returnUrl);
                }
                return returnUrl;
            }
        }
    }
</script>
