<template>
    <v-flex column>
        <h1 class="text-xs-center headline py-3">Success!</h1>
        <p class="text-xs-center" id="successMessage">
            <span v-html="message"></span>
        </p>
    </v-flex>
</template>

<script>
    export default {
        name: 'email-sent',
        props: ['message']
    }
</script>
