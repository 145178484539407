<template>
    <v-flex column v-if="!successMessage">
        <h1 class="text-xs-center headline py-3">Security Update</h1>
        <p class="text-xs-center">As we are updating our secure login for Dude Products, we require the following:</p>
        <v-form @submit.prevent="validateForm" model="valid" ref="form">
            <v-alert id="errorMessage" v-model="error"
                     type="error"
                     transition="slide-x-reverse-transition">
                <ul>
                    <li v-for="error in errorMessages"> {{ error.message }}</li>
                </ul>
            </v-alert>
            <v-text-field id="emailInput"
                          label="Email"
                          name="email"
                          ref="email"
                          prepend-icon="email"
                          :rules="formValidations.emailRules"
                          v-model="email"
                          hint="Email confirmation will be required. Please use a valid email address."
                          persistent-hint="true"/>
            <set-password ref="setPassword" />
            <v-layout justify-center class="pt-5">
                <v-flex xs8>
                    <v-btn id="importSubmit" block type="submit">Submit</v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-flex>
    <v-flex column v-else>
        <email-sent :message="successMessage" />
    </v-flex>
</template>
<script>
    import SetPassword from './Shared/SetPassword.vue';
    import EmailSent from './Shared/EmailSent.vue';
    import LoginContext from '../mixins/loginContext';

    const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    export default {
        mixins: [LoginContext],

        data() {
            return {
                token: '',
                subId: '',
                email: '',
                valid: false,
                error: false,
                errorMessages: '',

                formValidations: {
                    emailRules: [
                        e => (e && e.length >= 1) || 'Email is required',
                        e => emailRegex.test(e) || 'Please enter a valid email address'
                    ]
                },
                successMessage: null
            }
        },

        methods: {
            validateForm() {
                if (this.$refs.form.validate()) {
                    this.completeImport();
                }
            },

            async completeImport() {
                try {
                    const importResponse = await this.$identityApi.import.verify.post({
                        urlParams: {
                            subjectId: this.subId
                        },
                        payload: {
                            Token: this.token,
                            Email: this.email,
                            UserPassword: this.$refs.setPassword.newPassword,
                            ReturnUrl: this.loginContext.returnUrl
                        }
                    });

                    if (importResponse.data.emailSent) {
                        this.successMessage = 'Please check your email for next steps. You may close this page.';
                    } else {
                        this.$router.push({ name: 'login-password', params: { selectedUsername: importResponse.data.username } });
                    }

                }
                catch (updateError) {
                    this.error = true;
                    this.errorMessages = [];
                    const validationErrors = updateError.response.data;
                    this.errorMessages = validationErrors.errors.map(e => { return { code: e.code, message: e.message } });

                    if (0 <= this.errorMessages.findIndex(error => error.code.includes('Import_Token'))) {
                        this.$store.commit('notifications/notify',
                            {
                                text: "An error has occurred. Contact your administrator if the problem persists.",
                                type: 'error',
                                timeout: 0
                            });
                        this.$router.replace({ name: 'login-chooser' });
                    }
                    else {
                        this.$refs.setPassword.newPassword = '';
                        this.$refs.setPassword.confirmNewPassword = '';
                    }
                }
            }
        },

        components: {
            SetPassword,
            EmailSent
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.$identityApi.import.validateToken.get({
                    params: {
                        jwt: to.query.jwt
                    },
                    verifyRequest: true
                })
                .catch(() => {
                    const errorMessage = 'An error occurred when retrieving your information. Please try to log in again.';
                    vm.$store.commit('notifications/notify', { text: errorMessage, type: 'error', timeout: 0 });
                    vm.$router.push({ name: 'login-chooser' });
                })
                .then(r => {
                    vm.subId = r.data.subjectId;
                    vm.email = r.data.email;
                    vm.token = to.query.jwt;
                });
            })
        }
    }
</script>
