<template>
    <v-flex column v-if="passwordChangeSucceeded">
        <h1 class="text-xs-center headline py-3">Success!</h1>
        <p class="text-xs-center" id="successMessage">
            Your password has been successfully updated.<br/>
            You may close this page.
        </p>
    </v-flex>
    <v-flex column v-else>
        <h1 class="text-xs-center headline py-3">Ready to create a new password?</h1>

        <p class="text-xs-center">
            Simply complete the form below and hit <strong>Update Password</strong>.
        </p>
        <v-form @submit.prevent="set" v-model="valid" ref="form">
            <v-alert id="errorMessage" v-model="error"
                     dismissible
                     type="error"
                     transition="slide-x-reverse-transition">
                {{ errorMessage }}
            </v-alert>

            <v-text-field id="currentPasswordInput"
                          label="Current Password"
                          ref="currentPassword"
                          type="password"
                          name="CurrentPassword"
                          v-model="currentPassword"
                          prepend-icon="lock"
                          class="mt-5"
                          :rules="currentPasswordRules"
                          validate-on-blur />

            <set-password ref="setPassword" />

            <v-layout justify-center class="pt-5">
                <v-flex xs8>
                    <v-btn id="updatePasswordSubmit"
                           type="submit"
                           block>
                        Update Password
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-flex>
</template>

<script>
    import SetPassword from './Shared/SetPassword.vue';
    import identityApi from '../api';

    export default {
        data() {
            return {
                currentPassword: '',
                valid: false,
                error: false,
                errorMessage: '',
                currentPasswordRules: [
                    v => !!v || 'Current password is required'
                ],
                passwordChangeSucceeded: false
            }
        },

        methods: {
            set() {
                if (this.$refs.form.validate()) {
                    this.$identityApi.changePassword.post({
                        payload: {
                            CurrentPassword: this.currentPassword,
                            NewPassword: this.$refs.setPassword.newPassword
                        }
                    }).then(() => {
                        this.passwordChangeSucceeded = true;
                    }).catch(error => {
                        this.error = true;
                        this.errorMessage = error.response.data.errors.map(e => e.message).join('<br/>');

                        this.currentPassword = '';
                        this.$refs.setPassword.confirmNewPassword = '';
                        this.$refs.setPassword.newPassword = '';

                        this.$refs.currentPassword.focus();

                    });
                }
            }
        },

        components: {
            SetPassword
        },

        beforeRouteEnter(to, from, next) {
            identityApi.changePassword.get()
                .catch(() => {
                    next(vm => {
                        const authError = 'You must be logged in to change your password';
                        vm.$store.commit('notifications/notify', { text: authError, type: 'error', timeout: 0 });
                        vm.$emit('remember-route', vm.$route.name);
                        vm.$router.push({ name: 'login-chooser'});
                    });
                })
                .then(next);
        }
    }
</script>