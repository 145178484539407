<template>
    <v-flex column>
        <v-card class="logoutCard" elevation="24">
            <v-progress-linear rounded
                               height="6"
                               :active="spLoggingOut"
                               :indeterminate="spLoggingOut">
            </v-progress-linear>
            <v-alert id="errorMessage"
                     v-model="error"
                     dismissible
                     type="error"
                     transition="slide-x-reverse-transition">
                {{ errorMessage }}
            </v-alert>
            <div v-if="loggedOut && spLoggingOut" class="returnMessage">
                <v-card-title id="loggingOutMessage" class="text-xs-center">We are logging you out...</v-card-title>
                <v-card-text class="text-xs-center">Please don't close this window.</v-card-text>
                <iframe hidden="hidden" v-if="signOutIframeUrl" :src="signOutIframeUrl"></iframe>
            </div>
            <div v-else-if="loggedOut && !spLoggingOut">
                <v-card-title id="logoutConfirmationMessage" class="text-xs-center"> {{samlLogoutUrl ? "Redirecting you to logout of your organziation" : "You are now logged out."}}</v-card-title>
                <v-card-text class="text-xs-center" v-if="samlLogoutUrl">
                  If you are not automatically redirected please click <a id="samlLogoutUrl" :href="samlLogoutUrl">here</a>.
                </v-card-text>
              <v-card-text class="text-xs-center" v-else-if="postLogoutRedirectUri">
                Click <a id="postLogoutRedirectUri" :href="postLogoutRedirectUri">here</a> to return to the
                <em>{{clientName}}</em> application.
              </v-card-text>
              <v-card-text class="text-xs-center" v-else>You may now close the window.</v-card-text>
            </div>
            <v-form v-else @submit.prevent="logout">
                <div class="text-xs-center mt-2 mb-3">
                    <v-flex>
                        <v-card-title id="logoutMessage">Ready to log out?</v-card-title>
                    </v-flex>
                </div>
                <v-layout justify-center>
                    <v-flex xs8>
                        <v-card-actions>
                            <v-btn block id="signOut" type="submit" class="font-weight-bold">
                                Yes
                            </v-btn>
                        </v-card-actions>
                    </v-flex>
                </v-layout>
            </v-form>
        </v-card>
    </v-flex>
</template>

<script>
    import { mapState } from 'vuex';
    import store from '../store';

    export default {
        name: 'logout',

        props: ['initialLoggedOut', 'logoutId', 'initialPostLogoutRedirectUri', 'initialSignOutIframeUrl', 'initialClientName', "samlLogoutUrl"],

        data() {
            return {
                error: false,
                errorMessage: '',
                postLogoutRedirectUri: this.initialPostLogoutRedirectUri,
                signOutIframeUrl: this.initialSignOutIframeUrl,
                clientName: this.initialClientName,
                loggedOut: this.initialLoggedOut,
                samlLogoutUrl: this.samlLogoutUrl
            }
        },

        computed: mapState({
            spLoggingOut: state => state.logout.spLoggingOut
        }),

        methods: {
            logout() {
                this.$identityApi.logout.post({
                    payload: { LogoutId: this.logoutId }
                }).then(response => {
                    store.commit("logout/spLoggedOut", true);
                    if (response.data.postLogoutRedirectUri && !this.samlLogoutUrl) {
                        const self = this;
                        this.postLogoutRedirectUri = response.data.postLogoutRedirectUri;
                        setTimeout(() => {
                            window.location = self.postLogoutRedirectUri;
                        }, 5000);
                    } else if (this.samlLogoutUrl) {
                        const self = this;
                        setTimeout(() => {
                          window.location = self.samlLogoutUrl;
                        }, 5000);
                    }
                    this.clientName = response.data.clientName;
                    this.signOutIframeUrl = response.data.signOutIframeUrl;
                    this.loggedOut = true;
                    setTimeout(() => {
                        store.commit("logout/spLoggedOut", false);
                    }, 5000);
                }).catch(() => {
                    store.commit("logout/spLoggedOut", false);
                    this.error = true;
                    this.errorMessage = "There was an error logging out.";
                });
            }
        }
    }
</script>
