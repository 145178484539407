<template>
    <v-flex column v-if="!successMessage">
        <h1 class="text-xs-center title py-3">Need to reset your password?</h1>
        <p class="text-xs-center">
            Easily done. Enter your username below and we will send you an email to get that taken care of.
        </p>
        <v-form @submit.prevent="reset">
            <v-text-field id="username"
                          label="Username"
                          prepend-icon="account_circle"
                          v-model="username"
                          autofocus
                          :error-messages="validation"
                          @mousedown="validation=[]" />
            <v-layout justify-center class="pt-5">
                <v-btn id="forgotPasswordSubmit" type="submit">
                    Send Me Reset Instructions
                </v-btn>
            </v-layout>
            <v-layout justify-center pt-5>
                <v-btn id="helper-link" :to="{name: 'login-chooser'}" flat>
                    Return to login
                </v-btn>
            </v-layout>
        </v-form>
    </v-flex>
    <v-flex column v-else>
        <email-sent :message="successMessage" />
    </v-flex>
</template>

<script>
    import EmailSent from './Shared/EmailSent.vue';
    import loginContext from '../mixins/loginContext';

    export default {
        name: 'password-forgot',

        props: ['initialUsername'],

        mixins: [loginContext],

        data() {
            return {
                username: this.initialUsername,
                emailSent: false,
                validation: [],
                successMessage: null
            }
        },

        methods: {
            reset() {
                if (this.username) {
                    this.$identityApi.forgotPassword.post({
                        payload: {
                            Username: this.username,
                            ReturnUrl: this.loginContext.returnUrl
                        }
                    }).then(this.redirect)
                        .catch(this.redirect);
                }
                else {
                    this.validation = ['Enter your username']
                }
            },
            redirect() {
                this.successMessage =
                    'Please check your email for next steps to reset your password. If you have not receive an email in 2 hours, contact your administrator. You may close this page.';
            }
        },

        components: {
            EmailSent
        },
    }
</script>