export default {
    data() {
        return {
            loginContext: {
                accountId: "",
                returnUrl: "",
                allowSignUp: false,
                recaptchaSiteKey: ""
            }
        };
    },

    created() {
        const context = JSON.parse(document.querySelector("main[data-login-context]").dataset.loginContext);

        this.loginContext.accountId = context.AccountId;
        this.loginContext.returnUrl = context.ReturnUrl;
        this.loginContext.allowSignUp = context.AllowSelfRegistration;
        this.loginContext.recaptchaSiteKey = context.RecaptchaSiteKey;
    }
}