var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { column: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "text-xs-center headline" },
        [_c("v-flex", [_vm._v("Remove a user")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { "two-line": "", id: "userRemoveList" } },
        [
          _vm._l(_vm.identities.map(_vm.removeUserMap), function(user, index) {
            return [
              _c(
                "v-list-tile",
                { attrs: { id: "removeUserItem" } },
                [
                  _c("v-list-tile-avatar", { staticClass: "letter" }, [
                    _c(
                      "span",
                      { class: { headline: user.initials.length === 1 } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(user.initials) +
                            "\n                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  user.fullName.length > 1
                    ? _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v(_vm._s(user.fullName))
                          ]),
                          _vm._v(" "),
                          _c("v-list-tile-sub-title", [
                            _vm._v(_vm._s(user.username))
                          ])
                        ],
                        1
                      )
                    : _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v(_vm._s(user.username))
                          ])
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "v-list-tile-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", ripple: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.confirmRemove(index)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              index !== _vm.identities.length - 1
                ? _c("v-divider", { attrs: { inset: "" } })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "", "pt-5": "", "pb-5": "" } },
            [
              _c("v-divider", { attrs: { "pt-5": "", "pb-5": "" } }),
              _vm._v(" "),
              _c(
                "v-list-tile",
                { attrs: { to: { name: "login-chooser" } } },
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _c(
                        "v-list-tile-title",
                        { staticClass: "text-xs-right" },
                        [_vm._v("Done")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline remove-user" }, [
                _vm._v("Remove User?")
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("em", [
                  _vm._v(_vm._s(_vm.identities[_vm.selectedIndex].username))
                ]),
                _vm._v(
                  " will be removed from the list of users on the login screen "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      nativeOn: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "remove-user",
                      attrs: { flat: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.removeUser($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Yes, remove\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }