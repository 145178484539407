var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.successMessage
    ? _c(
        "v-flex",
        { attrs: { column: "" } },
        [
          _c("h1", { staticClass: "text-xs-center headline py-3" }, [
            _vm._v("Security Update")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-xs-center" }, [
            _vm._v(
              "As we are updating our secure login for Dude Products, we require the following:"
            )
          ]),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { model: "valid" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateForm($event)
                }
              }
            },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    id: "errorMessage",
                    type: "error",
                    transition: "slide-x-reverse-transition"
                  },
                  model: {
                    value: _vm.error,
                    callback: function($$v) {
                      _vm.error = $$v
                    },
                    expression: "error"
                  }
                },
                [
                  _c(
                    "ul",
                    _vm._l(_vm.errorMessages, function(error) {
                      return _c("li", [_vm._v(" " + _vm._s(error.message))])
                    }),
                    0
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-text-field", {
                ref: "email",
                attrs: {
                  id: "emailInput",
                  label: "Email",
                  name: "email",
                  "prepend-icon": "email",
                  rules: _vm.formValidations.emailRules,
                  hint:
                    "Email confirmation will be required. Please use a valid email address.",
                  "persistent-hint": "true"
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              }),
              _vm._v(" "),
              _c("set-password", { ref: "setPassword" }),
              _vm._v(" "),
              _c(
                "v-layout",
                { staticClass: "pt-5", attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs8: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "importSubmit",
                            block: "",
                            type: "submit"
                          }
                        },
                        [_vm._v("Submit")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-flex",
        { attrs: { column: "" } },
        [_c("email-sent", { attrs: { message: _vm.successMessage } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }