var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-flex", { attrs: { column: "" } }, [
    _c("h1", { staticClass: "text-xs-center headline py-3" }, [
      _vm._v("Page Not Found")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }