var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.successMessage
    ? _c(
        "v-flex",
        { attrs: { column: "" } },
        [
          _c("h1", { staticClass: "text-xs-center title py-3" }, [
            _vm._v("Need to reset your password?")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-xs-center" }, [
            _vm._v(
              "\n        Easily done. Enter your username below and we will send you an email to get that taken care of.\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.reset($event)
                }
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  id: "username",
                  label: "Username",
                  "prepend-icon": "account_circle",
                  autofocus: "",
                  "error-messages": _vm.validation
                },
                on: {
                  mousedown: function($event) {
                    _vm.validation = []
                  }
                },
                model: {
                  value: _vm.username,
                  callback: function($$v) {
                    _vm.username = $$v
                  },
                  expression: "username"
                }
              }),
              _vm._v(" "),
              _c(
                "v-layout",
                { staticClass: "pt-5", attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { id: "forgotPasswordSubmit", type: "submit" } },
                    [
                      _vm._v(
                        "\n                Send Me Reset Instructions\n            "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { "justify-center": "", "pt-5": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "helper-link",
                        to: { name: "login-chooser" },
                        flat: ""
                      }
                    },
                    [_vm._v("\n                Return to login\n            ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-flex",
        { attrs: { column: "" } },
        [_c("email-sent", { attrs: { message: _vm.successMessage } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }