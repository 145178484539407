var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.successMessage
    ? _c(
        "v-flex",
        { attrs: { column: "" } },
        [
          _c(
            "h1",
            {
              staticClass: "text-xs-center headline py-3",
              attrs: { id: "errorHeading" }
            },
            [_vm._v("Email Verification Required")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "text-xs-center" }, [
            _vm._v(
              '\n        Please provide your username and click "Send Email" to complete your email verification.\n    '
            )
          ]),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.resend($event)
                }
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  id: "username",
                  label: "Username",
                  "prepend-icon": "account_circle",
                  autofocus: "",
                  "error-messages": _vm.validation
                },
                on: {
                  mousedown: function($event) {
                    _vm.validation = []
                  }
                },
                model: {
                  value: _vm.username,
                  callback: function($$v) {
                    _vm.username = $$v
                  },
                  expression: "username"
                }
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "text-xs-center",
                  attrs: { id: "contactAdmin" }
                },
                [
                  _vm._v(
                    "Please contact your administrator if you continue to have issues."
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs8: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "userActivationSubmit",
                            disabled: _vm.activationError,
                            type: "submit",
                            block: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Send Email\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-flex",
        { attrs: { column: "" } },
        [_c("email-sent", { attrs: { message: _vm.successMessage } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }