var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c("v-text-field", {
        attrs: {
          id: "passwordInput",
          label: "New Password",
          name: "NewPassword",
          rules: _vm.passwordRules,
          disabled: _vm.disabled,
          type: "password",
          "prepend-icon": "lock",
          "validate-on-blur": "",
          hint: "Use 8 or more characters and 1 number or symbol (e.g. 123!@#)"
        },
        model: {
          value: _vm.newPassword,
          callback: function($$v) {
            _vm.newPassword = $$v
          },
          expression: "newPassword"
        }
      }),
      _vm._v(" "),
      _c("v-text-field", {
        attrs: {
          id: "confirmNewPasswordInput",
          label: "Confirm New Password",
          name: "ConfirmNewPassword",
          rules: _vm.confirmRules,
          disabled: _vm.disabled,
          type: "password",
          "prepend-icon": "lock",
          "validate-on-blur": ""
        },
        model: {
          value: _vm.confirmNewPassword,
          callback: function($$v) {
            _vm.confirmNewPassword = $$v
          },
          expression: "confirmNewPassword"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }