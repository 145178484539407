<template>
    <v-flex column>
        <h1 class="text-xs-center headline py-3">Ready to create a new password?</h1>
        <p class="text-xs-center">
            Simply complete the form below and hit <strong>Update Password</strong>.
        </p>
        <v-form @submit.prevent="set" v-model="valid" ref="form">
            <v-alert id="errorMessage" v-model="error"
                     dismissible
                     type="error"
                     transition="slide-x-reverse-transition">
                {{ errorMessage }}
            </v-alert>

            <input type="hidden" name="ResetToken" :value="resetToken" />
            <input type="hidden" name="Username" :value="username" />

            <v-text-field label="Username"
                          prepend-icon="account_circle"
                          v-model="username"
                          id="resetPasswordUsernameInput"
                          disabled />

            <set-password ref="setPassword" />

            <v-layout justify-center class="pt-5">
                <v-flex xs8>
                    <v-btn id="updatePasswordSubmit" block type="submit">Update Password</v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-flex>
</template>

<script>
    import SetPassword from './Shared/SetPassword.vue';

    export default {
        name: 'password-reset',
        props: ['resetToken', 'username'],

        data() {
            return {
                valid: false,
                error: false,
                errorMessage: '',
                usernameRules: [
                    v => !!v || 'Username is required'
                ]
            }
        },

        methods: {
            set() {
                if (this.$refs.form.validate()) {
                    this.$identityApi.resetPassword.post({
                        payload: {
                            username: this.username,
                            resetToken: this.resetToken,
                            newPassword: this.$refs.setPassword.newPassword
                        }
                    }).then(request => {
                        this.success(request.data)
                    }).catch((resetError) => {
                        this.error = true;
                        this.$refs.setPassword.newPassword = '';
                        this.$refs.setPassword.confirmNewPassword = '';
                        const validationErrors = resetError.response.data;
                        this.errorMessage = validationErrors.errors.map(e => e.message).join('');
                    });
                }
            },

            success(responseData) {
                const successMessage = 'Your password has been reset.  Let\'s give it a try!';
                this.$router.push({
                    name: 'login-password',
                    params: {
                        selectedUsername: responseData.username
                    }
                });
                this.$emit('notify', { text: successMessage, type: 'done' });
            }
        },

        components: {
            SetPassword
        }
    }
</script>