<template>
    <v-flex v-if="requiresPassword" column>
        <h1 class="text-xs-center headline py-3">User Activation</h1>
        <v-form @submit.prevent="activate" ref="form">
            <v-alert id="errorMessage" v-model="activationError"
                     dismissible
                     type="error"
                     transition="slide-x-reverse-transition">
                {{ errorMessage }}
            </v-alert>

            <set-password ref="setPassword" :disabled="activationError" />

            <v-layout justify-center>
                <v-flex xs8>
                    <v-btn id="userActivationSubmit"
                           :disabled="activationError"
                           type="submit"
                           block>
                        Activate
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-flex>
</template>

<script>
    import SetPassword from '../Shared/SetPassword.vue';

    export default {
        name: 'activate',

        data() {
            return {
                activationError: false,
                errorMessage: '',
                requiresPassword: false,
                token: '',
                username: ''
            }
        },

        methods: {
            activate() {
                if (this.$refs.form.validate()) {
                    this.$identityApi.activate.post({
                        payload: {
                            token: this.token,
                            username: this.username,
                            password: this.$refs.setPassword.newPassword
                        },verifyRequest:true
                    }).then(() => {
                        var message = 'User has been successfully activated.';
                        this.$router.replace({ name: 'login-password', params: { selectedUsername: this.username } });
                        this.$emit('notify', {
                            text: message,
                            type: 'done',
                            timeout: 0
                        });
                    }).catch((error) => {
                        const validationErrors = error.response.data;
                        this.activationError = true;
                        this.errorMessage = validationErrors.errors.map(e => e.message).join('<br/>');
                        this.$refs.form.clear();
                    });
                }
            },

            // All of the methods below are for handling routing in beforeRouteEnter
            getActivationContext(to) {
                return this.$identityApi.activate.get({
                    params: {
                        token: to.query.token,
                        returnUrl: to.query.returnUrl
                    },
                    verifyRequest: true
                })
            },

            verifyEmailIfNeeded(activationContext) {
                if (activationContext.requiresEmailVerification) {
                    return this.$identityApi.verifyEmail.post({
                        payload: {
                            Token: activationContext.token
                        },
                        verifyRequest: true
                    });
                }
            },

            handleActivationContext(activationContext) {
                if (activationContext.type === 'import') {
                    window.location = activationContext.redirectUri;
                    return true;
                }

                if (activationContext.type === 'password') {
                    if (activationContext.requiresPassword) {
                        this.requiresPassword = true;
                        return true;
                    }
                }

                if (activationContext.requiresEmailVerification) {
                    this.$store.commit('notifications/notify',
                        {
                            text: 'You have successfully verified your email. Please login',
                            type: 'done',
                            timeout: 0
                        });
                }

                if (activationContext.type === 'saml') {
                    // Add the logic for redirecting to the saml idp.
                    if (activationContext.providers.length === 1) {
                        const samlParams = new URLSearchParams();
                        const samlUrl = new URL('/sso/saml2/authn', window.location.href);
                        const returnUrl = JSON.parse(document.querySelector("main[data-login-context]").dataset
                            .loginContext).ReturnUrl;
                        samlParams.set('id', activationContext.providers[0].id);
                        samlParams.set('returnUrl', returnUrl);
                        samlUrl.search = samlParams;
                        // Adding a timeout to delay the redirect to give the user enough time to see the notification.
                        window.setTimeout(function () {
                          window.location = samlUrl;
                        }, 2000);
                        return true;
                    }
                    this.$router.push({ name: 'saml-chooser', params: { providers: activationContext.providers, username: activationContext.username } });
                    return true;
                }

                this.$router.push({ name: 'login-password', params: { selectedUsername: activationContext.username } });
            },

            hanldeRoutingErrors(error) {
                const errors = error.response.data.errors;
                const errorMessage = error.response.data.errors.map(e => e.message).join('</br>');
                var navigateTo = 'login-chooser';
                if (errors.filter(e => e.code === 'Activation_Token_InvalidOrExpired' || e.code === 'EmailVerification_Token_InvalidOrExpired').length > 0) {
                    navigateTo = 'email-resend';
                }
                this.$router.push({ name: navigateTo });
                this.$store.commit('notifications/notify',
                    {
                        text: errorMessage,
                        type: 'error',
                        timeout: 0
                    });
            }
        },

        components: {
            SetPassword
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                var response = {};
                if (to.query.token) {
                    vm.getActivationContext(to)
                        .then(r => {
                            response = r.data;
                            return vm.verifyEmailIfNeeded(response);
                        })
                        .then(() => {
                            vm.handleActivationContext(response);
                            if (vm.requiresPassword) {
                                vm.token = response.token;
                                vm.username = response.username;
                            }
                        })
                        .catch(vm.hanldeRoutingErrors);
                } else {
                    vm.$router.push({ name: 'login-chooser' });
                }
            });
        }
    }
</script>
