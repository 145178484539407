var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.requiresPassword
    ? _c(
        "v-flex",
        { attrs: { column: "" } },
        [
          _c("h1", { staticClass: "text-xs-center headline py-3" }, [
            _vm._v("User Activation")
          ]),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.activate($event)
                }
              }
            },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    id: "errorMessage",
                    dismissible: "",
                    type: "error",
                    transition: "slide-x-reverse-transition"
                  },
                  model: {
                    value: _vm.activationError,
                    callback: function($$v) {
                      _vm.activationError = $$v
                    },
                    expression: "activationError"
                  }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.errorMessage) + "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("set-password", {
                ref: "setPassword",
                attrs: { disabled: _vm.activationError }
              }),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs8: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "userActivationSubmit",
                            disabled: _vm.activationError,
                            type: "submit",
                            block: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Activate\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }