<template>
    <v-layout column justify-space-around fill-height>
        <v-flex>
            <h1 class="text-xs-center headline pa-3">Choose a user</h1>
        </v-flex>
        <v-flex>
            <v-list two-line>
                <template v-for="(user, index) in identities.map(userChooserMap)">
                    <v-list-tile :to="{ name: 'login-password', params: { selectedUsername: user.username, selectedIndex: index }}" id="user">
                        <v-list-tile-avatar class="letter">
                            <span :class="{ 'headline': user.initials.length === 1 }">
                                {{ user.initials }}
                            </span>
                        </v-list-tile-avatar>
                        <v-list-tile-content v-if="user.fullName.length > 1">
                            <v-list-tile-title>{{ user.fullName }}</v-list-tile-title>
                            <v-list-tile-sub-title>{{ user.username }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-content v-else>
                            <v-list-tile-title>{{ user.username }}</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-divider inset />
                </template>
                <v-list-tile :to="{ name: 'login-username' }" id="newUser">
                    <v-list-tile-avatar>
                        <v-icon>account_circle</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                        <v-list-tile-title>Sign-in with another user</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list dense>
                    <v-divider pt-5 pb-5 />
                    <v-list-tile :to="{ name: 'chooser-remove' }" id="removeUser">
                        <v-list-tile-content>
                            <v-list-tile-title class="text-xs-right">Remove a user</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </v-list>
            </v-list>
            <div v-if="loginContext.allowSignUp" class="text-xs-center pt-4">
                <router-link id="signUpLink" :to="{name: 'signup'}">Sign up as new user</router-link>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
    import knownIdentities from '../../mixins/knownIdentities';
    import loginContext from '../../mixins/loginContext';

    export default {
        name: 'chooser',

        mixins: [knownIdentities, loginContext],

        methods: {
            userChooserMap(user) {
                const initials = (user.givenName && user.familyName ?
                    `${user.givenName.charAt(0)}${user.familyName.charAt(0)}` :
                    user.username.charAt(0)).toLocaleUpperCase();

                const fullName = [user.givenName, user.familyName].join(' ');

                return {
                    username: user.username,
                    fullName: fullName,
                    initials: initials
                }
            }
        }
    }
</script>
