<template>
    <v-flex column v-if="!successMessage">
        <h1 class="text-xs-center headline py-3">New user signup</h1>
        <v-form @submit.prevent="validateForm" model="valid" ref="form">
            <v-alert id="errorMessage" v-model="error"
                     :dismissible="Boolean(loginContext.returnUrl)"
                     type="error"
                     transition="slide-x-reverse-transition">
                <ul>
                    <li v-for="(error) in errorMessages"> {{ error.message }}</li>
                </ul>
            </v-alert>
            <v-text-field id="emailInput"
                          label="Email"
                          name="email"
                          ref="email"
                          prepend-icon="email"
                          :rules="formValidations.emailRules"
                          v-model="email" />
            <set-password ref="setPassword" />
            <v-text-field id="firstNameInput"
                          label="First Name"
                          ref="firstName"
                          name="firstName"
                          prepend-icon="account_circle"
                          :rules="formValidations.firstNameRules"
                          v-model="firstName" />
            <v-text-field id="lastNameInput"
                          label="Last Name"
                          ref="lastName"
                          name="lastName"
                          prepend-icon="account_circle"
                          :rules="formValidations.lastNameRules"
                          v-model="lastName" />
            <v-layout justify-center class="pt-5">
                <v-flex xs8>
                    <vue-recaptcha ref="invisibleRecaptcha"
                                   @verify="onRecaptchaVerify"
                                   size="invisible"
                                   :sitekey="loginContext.recaptchaSiteKey"/>
                    <v-btn id="signupSubmit" block type="submit" :disabled="!loginContext.returnUrl">Submit</v-btn>
                </v-flex>
            </v-layout>
            <v-flex class="text-xs-center caption google-ts px-3 pt-3">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </v-flex>
        </v-form>
    </v-flex>
    <v-flex column v-else>
        <email-sent :message="successMessage" />
    </v-flex>
</template>
<script>
    import SetPassword from './Shared/SetPassword.vue';
    import EmailSent from './Shared/EmailSent.vue';
    import LoginContext from '../mixins/loginContext';
    import VueRecaptcha from 'vue-recaptcha';

    const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    export default {
        mixins: [LoginContext],

        data() {
            return {
                firstName: '',
                lastName: '',
                email: '',
                recaptchaResponse: null,

                valid: false,
                error: false,
                errorMessages: '',

                formValidations: {
                    firstNameRules: [fn => (fn && fn.length >= 1) || 'First Name is required'],
                    lastNameRules: [ln => (ln && ln.length >= 1) || 'Last Name is required'],
                    emailRules: [
                        e => (e && e.length >= 1) || 'Email is required',
                        e => emailRegex.test(e) || 'Please enter a valid email address'
                    ]
                },
                successMessage: null
            }
        },

        methods: {
            validateForm() {
                if (this.$refs.form.validate()) {
                    if (this.recaptchaResponse) {
                        this.signup();
                    } else {
                        this.$refs.invisibleRecaptcha.execute();
                    }
                }
            },

            async signup() {
                try {
                    await this.$identityApi.signups.post({
                        payload: {
                            Email: this.email,
                            Password: this.$refs.setPassword.newPassword,
                            GivenName: this.firstName,
                            FamilyName: this.lastName,
                            ReturnUrl: this.loginContext.returnUrl,
                            'g-recaptcha-response': this.recaptchaResponse
                        }
                    });
                    this.successMessage = 'Please check your email for next steps. You may close this page.';
                }

                catch (signupError) {
                    this.error = true;
                    this.errorMessages = [];
                    const validationErrors = signupError.response.data;
                    this.errorMessages = validationErrors.errors.map(e => { return { code: e.code, message: e.message } });
                    if (0 <= this.errorMessages.findIndex(error => error.code.includes('User_Password'))) {
                        this.$refs.setPassword.newPassword = '';
                        this.$refs.setPassword.confirmNewPassword = '';
                    }

                }
            },

            onRecaptchaVerify(response) {
                this.recaptchaResponse = response;

                this.signup();
            }
        },

        components: {
            SetPassword,
            EmailSent,
            VueRecaptcha
        },

        beforeMount() {
            if (!this.loginContext.returnUrl) {
                this.error = true;
                this.errorMessages =
                    [
                        {
                            "message": "Please start the sign up process from the application for which you want to register."
                        }
                    ];
            }
        }
    }
</script>
