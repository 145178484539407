import { mapState, mapActions } from 'vuex';

export default {
    computed: mapState({
        identities: state => state.identities.knownIdentities
    }),

    methods: mapActions('identities', [
        'upsertIdentityToKnownIdentities',
        'removeIdentityFromKnownIdentities'
    ])
}