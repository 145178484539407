var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "justify-space-around": "", "fill-height": "" } },
    [
      _c("v-flex", [
        _c("h1", { staticClass: "text-xs-center headline pa-3" }, [
          _vm._v("Choose your provider")
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-flex",
        [
          _c(
            "v-list",
            [
              _vm._l(_vm.providers, function(provider, index) {
                return [
                  _c(
                    "v-list-tile",
                    {
                      staticClass: "provider",
                      on: {
                        click: function($event) {
                          return _vm.redirectSaml(provider)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-content",
                        [
                          _c(
                            "v-list-tile-title",
                            {
                              staticClass: "text-xs-center",
                              attrs: { id: "provider-" + provider.id }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(provider.name) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }