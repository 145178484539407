<template>
    <v-layout column>
        <v-text-field id="passwordInput"
                      label="New Password"
                      name="NewPassword"
                      v-model="newPassword"
                      :rules="passwordRules"
                      :disabled="disabled"
                      type="password"
                      prepend-icon="lock"
                      validate-on-blur
                      hint="Use 8 or more characters and 1 number or symbol (e.g. 123!@#)"/>

        <v-text-field id="confirmNewPasswordInput"
                      label="Confirm New Password"
                      name="ConfirmNewPassword"
                      v-model="confirmNewPassword"
                      :rules="confirmRules"
                      :disabled="disabled"
                      type="password"
                      prepend-icon="lock"
                      validate-on-blur />
    </v-layout>
</template>

<script>
    export default {
        props: ['disabled'],

        data() {
            return {
                newPassword: '',
                confirmNewPassword: '',
                passwordRules: [
                    v => (v && v.length >= 8) || 'Password must be greater than 7 characters',
                    v => /[\W\d]/.test(v) || 'Password must contain at least one number and one symbol'
                ],
                confirmRules: [
                    v => v == this.newPassword || v.length === 0 || 'Passwords do not match',
                    v => v.length > 0 || 'Password confirmation is required'
                ]
            }
        }
    }
</script>