import { render, staticRenderFns } from "./Activate.vue?vue&type=template&id=0d910b85&"
import script from "./Activate.vue?vue&type=script&lang=js&"
export * from "./Activate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/TeamCity/BuildAgent/work/ceb80229c07d09f8/src/DSI.Identity.Api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('0d910b85', component.options)
    } else {
      api.reload('0d910b85', component.options)
    }
    module.hot.accept("./Activate.vue?vue&type=template&id=0d910b85&", function () {
      api.rerender('0d910b85', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Content/js/components/Activation/Activate.vue"
export default component.exports