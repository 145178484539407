<template>
    <v-flex column>
        <v-card-title class="text-xs-center headline">
            <v-flex>Remove a user</v-flex>
        </v-card-title>

        <v-list two-line id="userRemoveList">
            <template v-for="(user, index) in identities.map(removeUserMap)">
                <v-list-tile id="removeUserItem">
                    <v-list-tile-avatar class="letter">
                        <span :class="{ 'headline': user.initials.length === 1 }">
                            {{ user.initials }}
                        </span>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="user.fullName.length > 1">
                        <v-list-tile-title>{{ user.fullName }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ user.username }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-list-tile-title>{{ user.username }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                        <v-btn icon ripple @click.native="confirmRemove(index)">
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </v-list-tile-action>
                </v-list-tile>
                <v-divider inset v-if="index !== identities.length - 1" />
            </template>
            <v-list dense pt-5 pb-5>
                <v-divider pt-5 pb-5 />
                <v-list-tile :to="{ name: 'login-chooser' }">
                    <v-list-tile-content>
                        <v-list-tile-title class="text-xs-right">Done</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list>
        </v-list>
        <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="headline remove-user">Remove User?</v-card-title>
                <v-card-text>
                    <em>{{ identities[selectedIndex].username }}</em> will be removed from the list of users on the login screen </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn flat @click.native="dialog = false">
                        Cancel
                    </v-btn>
                    <v-btn flat @click.native="removeUser" class="remove-user">
                        Yes, remove
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-flex>
</template>

<script>
    import knownIdentities from '../../mixins/knownIdentities';

    export default {
        name: 'chooser-remove',

        mixins: [knownIdentities],

        data() {
            return {
                dialog: false,
                selectedIndex: 0
            }
        },

        methods: {
            confirmRemove(index) {
                this.dialog = true;
                this.selectedIndex = index;
            },

            removeUser() {
                this.dialog = false;

                this.removeIdentityFromKnownIdentities(this.selectedIndex);

                this.selectedIndex = 0;

                if (this.identities.length === 0) {
                    this.$router.replace({ name: 'login-username' });
                }
            },

            removeUserMap(user) {
                const initials = (user.givenName && user.familyName ?
                    `${user.givenName.charAt(0)}${user.familyName.charAt(0)}` :
                    user.username.charAt(0)).toLocaleUpperCase();

                const fullName = [user.givenName, user.familyName].join(' ');

                return {
                    username: user.username,
                    fullName: fullName,
                    initials: initials
                }
            }
        }
    }
</script>
