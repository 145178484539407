<template>
    <v-flex column v-if="!successMessage">
        <h1 id="errorHeading" class="text-xs-center headline py-3">Email Verification Required</h1>
        <p class="text-xs-center">
            Please provide your username and click "Send Email" to complete your email verification.
        </p>
        <v-form @submit.prevent="resend" ref="form">
            <v-text-field id="username"
                          label="Username"
                          prepend-icon="account_circle"
                          v-model="username"
                          autofocus
                          :error-messages="validation"
                          @mousedown="validation=[]" />
            <p class="text-xs-center" id="contactAdmin">Please contact your administrator if you continue to have issues.</p>
            <v-layout justify-center>
                <v-flex xs8>
                    <v-btn id="userActivationSubmit" :disabled="activationError" type="submit" block>
                        Send Email
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-flex>
    <v-flex column v-else>
        <email-sent :message="successMessage" />
    </v-flex>
</template>

<script>
    import EmailSent from './Shared/EmailSent.vue';
    import LoginContext from '../mixins/loginContext';
    export default {
        mixins: [LoginContext],

        name: 'email-resend',
        props: ['initialUsername'],
        data() {
            return {
                username: this.initialUsername,
                validation: [],
                activationError: false,
                successMessage: null
            }
        },
        methods: {
            resend() {
                if (this.username) {
                    this.$identityApi.verifyEmailRequest.post({
                        payload: {
                            username: this.username,
                            returnUrl: this.loginContext.returnUrl
                        }
                    }).then(() => {
                        this.successMessage =
                            'Please check your email for next steps. If you do not receive an email in 2 hours, please contact your administrator. You may close this page.';
                    }).catch(() => {
                        const validationErrors = error.response.data;

                        this.errorMessage = validationErrors.errors.map(e => e.message).join('<br/>');
                        this.activationError = true,
                            this.$emit('notify', {
                                text: message,
                                type: 'error',
                                timeout: 0
                            });
                        this.$router.replace({ name: 'login-chooser' });
                    });
                }
                else {
                    this.validation = ['Enter your username']
                }
            }
        },

        components: {
            EmailSent
        }
    }
</script>
