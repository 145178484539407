var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.successMessage
    ? _c(
        "v-flex",
        { attrs: { column: "" } },
        [
          _c("h1", { staticClass: "text-xs-center headline py-3" }, [
            _vm._v("New user signup")
          ]),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { model: "valid" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateForm($event)
                }
              }
            },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    id: "errorMessage",
                    dismissible: Boolean(_vm.loginContext.returnUrl),
                    type: "error",
                    transition: "slide-x-reverse-transition"
                  },
                  model: {
                    value: _vm.error,
                    callback: function($$v) {
                      _vm.error = $$v
                    },
                    expression: "error"
                  }
                },
                [
                  _c(
                    "ul",
                    _vm._l(_vm.errorMessages, function(error) {
                      return _c("li", [_vm._v(" " + _vm._s(error.message))])
                    }),
                    0
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-text-field", {
                ref: "email",
                attrs: {
                  id: "emailInput",
                  label: "Email",
                  name: "email",
                  "prepend-icon": "email",
                  rules: _vm.formValidations.emailRules
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              }),
              _vm._v(" "),
              _c("set-password", { ref: "setPassword" }),
              _vm._v(" "),
              _c("v-text-field", {
                ref: "firstName",
                attrs: {
                  id: "firstNameInput",
                  label: "First Name",
                  name: "firstName",
                  "prepend-icon": "account_circle",
                  rules: _vm.formValidations.firstNameRules
                },
                model: {
                  value: _vm.firstName,
                  callback: function($$v) {
                    _vm.firstName = $$v
                  },
                  expression: "firstName"
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                ref: "lastName",
                attrs: {
                  id: "lastNameInput",
                  label: "Last Name",
                  name: "lastName",
                  "prepend-icon": "account_circle",
                  rules: _vm.formValidations.lastNameRules
                },
                model: {
                  value: _vm.lastName,
                  callback: function($$v) {
                    _vm.lastName = $$v
                  },
                  expression: "lastName"
                }
              }),
              _vm._v(" "),
              _c(
                "v-layout",
                { staticClass: "pt-5", attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs8: "" } },
                    [
                      _c("vue-recaptcha", {
                        ref: "invisibleRecaptcha",
                        attrs: {
                          size: "invisible",
                          sitekey: _vm.loginContext.recaptchaSiteKey
                        },
                        on: { verify: _vm.onRecaptchaVerify }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "signupSubmit",
                            block: "",
                            type: "submit",
                            disabled: !_vm.loginContext.returnUrl
                          }
                        },
                        [_vm._v("Submit")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { staticClass: "text-xs-center caption google-ts px-3 pt-3" },
                [
                  _vm._v(
                    "\n            This site is protected by reCAPTCHA and the Google\n            "
                  ),
                  _c(
                    "a",
                    { attrs: { href: "https://policies.google.com/privacy" } },
                    [_vm._v("Privacy Policy")]
                  ),
                  _vm._v(" and\n            "),
                  _c(
                    "a",
                    { attrs: { href: "https://policies.google.com/terms" } },
                    [_vm._v("Terms of Service")]
                  ),
                  _vm._v(" apply.\n        ")
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-flex",
        { attrs: { column: "" } },
        [_c("email-sent", { attrs: { message: _vm.successMessage } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }