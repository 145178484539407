var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { column: "" } },
    [
      _c("h1", { staticClass: "text-xs-center headline py-3" }, [
        _vm._v("Log in to your account.")
      ]),
      _vm._v(" "),
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.checkUsername($event)
            }
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              id: "username",
              label: "Username",
              "prepend-icon": "account_circle",
              autofocus: "",
              "error-messages": _vm.validation
            },
            on: {
              mousedown: function($event) {
                _vm.validation = []
              }
            },
            model: {
              value: _vm.username,
              callback: function($$v) {
                _vm.username = $$v
              },
              expression: "username"
            }
          }),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs8: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-bold",
                      attrs: { id: "continueBtn", type: "submit", block: "" }
                    },
                    [_vm._v("\n                    Continue\n                ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.loginContext.allowSignUp
            ? _c(
                "div",
                { staticClass: "text-xs-center pt-4" },
                [
                  _c(
                    "router-link",
                    { attrs: { id: "signUpLink", to: { name: "signup" } } },
                    [_vm._v("Sign up as new user")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }