var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "justify-space-around": "", "fill-height": "" } },
    [
      _c("v-flex", [
        _c("h1", { staticClass: "text-xs-center headline pa-3" }, [
          _vm._v("Choose a user")
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-flex",
        [
          _c(
            "v-list",
            { attrs: { "two-line": "" } },
            [
              _vm._l(_vm.identities.map(_vm.userChooserMap), function(
                user,
                index
              ) {
                return [
                  _c(
                    "v-list-tile",
                    {
                      attrs: {
                        to: {
                          name: "login-password",
                          params: {
                            selectedUsername: user.username,
                            selectedIndex: index
                          }
                        },
                        id: "user"
                      }
                    },
                    [
                      _c("v-list-tile-avatar", { staticClass: "letter" }, [
                        _c(
                          "span",
                          { class: { headline: user.initials.length === 1 } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(user.initials) +
                                "\n                        "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      user.fullName.length > 1
                        ? _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(_vm._s(user.fullName))
                              ]),
                              _vm._v(" "),
                              _c("v-list-tile-sub-title", [
                                _vm._v(_vm._s(user.username))
                              ])
                            ],
                            1
                          )
                        : _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(_vm._s(user.username))
                              ])
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", { attrs: { inset: "" } })
                ]
              }),
              _vm._v(" "),
              _c(
                "v-list-tile",
                { attrs: { to: { name: "login-username" }, id: "newUser" } },
                [
                  _c(
                    "v-list-tile-avatar",
                    [_c("v-icon", [_vm._v("account_circle")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-tile-content",
                    [
                      _c("v-list-tile-title", [
                        _vm._v("Sign-in with another user")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c("v-divider", { attrs: { "pt-5": "", "pb-5": "" } }),
                  _vm._v(" "),
                  _c(
                    "v-list-tile",
                    {
                      attrs: {
                        to: { name: "chooser-remove" },
                        id: "removeUser"
                      }
                    },
                    [
                      _c(
                        "v-list-tile-content",
                        [
                          _c(
                            "v-list-tile-title",
                            { staticClass: "text-xs-right" },
                            [_vm._v("Remove a user")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _vm.loginContext.allowSignUp
            ? _c(
                "div",
                { staticClass: "text-xs-center pt-4" },
                [
                  _c(
                    "router-link",
                    { attrs: { id: "signUpLink", to: { name: "signup" } } },
                    [_vm._v("Sign up as new user")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }