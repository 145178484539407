var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { column: "" } },
    [
      _c("h1", { staticClass: "text-xs-center headline py-3" }, [
        _vm._v("Ready to create a new password?")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-xs-center" }, [
        _vm._v("\n        Simply complete the form below and hit "),
        _c("strong", [_vm._v("Update Password")]),
        _vm._v(".\n    ")
      ]),
      _vm._v(" "),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.set($event)
            }
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-alert",
            {
              attrs: {
                id: "errorMessage",
                dismissible: "",
                type: "error",
                transition: "slide-x-reverse-transition"
              },
              model: {
                value: _vm.error,
                callback: function($$v) {
                  _vm.error = $$v
                },
                expression: "error"
              }
            },
            [_vm._v("\n            " + _vm._s(_vm.errorMessage) + "\n        ")]
          ),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "ResetToken" },
            domProps: { value: _vm.resetToken }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "Username" },
            domProps: { value: _vm.username }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: "Username",
              "prepend-icon": "account_circle",
              id: "resetPasswordUsernameInput",
              disabled: ""
            },
            model: {
              value: _vm.username,
              callback: function($$v) {
                _vm.username = $$v
              },
              expression: "username"
            }
          }),
          _vm._v(" "),
          _c("set-password", { ref: "setPassword" }),
          _vm._v(" "),
          _c(
            "v-layout",
            { staticClass: "pt-5", attrs: { "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs8: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "updatePasswordSubmit",
                        block: "",
                        type: "submit"
                      }
                    },
                    [_vm._v("Update Password")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }