var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { column: "" } },
    [
      _c("h1", { staticClass: "text-xs-center title py-3" }, [
        _vm._v("\n        Welcome "),
        _vm.givenName
          ? _c("span", { staticClass: "given-name" }, [
              _vm._v(_vm._s(_vm.givenName))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-center": "" } },
        [
          _c(
            "v-btn",
            { attrs: { id: "backButton", round: "" }, on: { click: _vm.back } },
            [
              _c("v-icon", [_vm._v("arrow_back")]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "pl-3", attrs: { id: "selectedUsername" } },
                [_vm._v(_vm._s(_vm.selectedUsername))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.login($event)
            }
          }
        },
        [
          _c(
            "v-alert",
            {
              attrs: {
                id: "errorMessage",
                dismissible: "",
                type: "error",
                transition: "slide-x-reverse-transition"
              },
              model: {
                value: _vm.error,
                callback: function($$v) {
                  _vm.error = $$v
                },
                expression: "error"
              }
            },
            [_vm._v("\n            " + _vm._s(_vm.errorMessage) + "\n        ")]
          ),
          _vm._v(" "),
          _c("v-text-field", {
            ref: "password",
            staticClass: "mt-4",
            attrs: {
              id: "password",
              label: "Enter password",
              "append-icon": _vm.showMask ? "visibility_off" : "visibility",
              type: _vm.showMask ? "password" : "text",
              name: "Password",
              "error-messages": _vm.validation,
              "prepend-icon": "lock",
              autofocus: ""
            },
            on: {
              mousedown: function($event) {
                _vm.validation = []
              },
              "click:append": function($event) {
                _vm.showMask = !_vm.showMask
              }
            },
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = $$v
              },
              expression: "password"
            }
          }),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-space-between": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      id: "rememberMe",
                      label: "Remember Me",
                      name: "RememberMe"
                    },
                    model: {
                      value: _vm.rememberMe,
                      callback: function($$v) {
                        _vm.rememberMe = $$v
                      },
                      expression: "rememberMe"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { staticClass: "text-xs-right", attrs: { xs6: "" } },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        id: "forgotPasswordLink",
                        to: {
                          name: "password-forgot",
                          params: { initialUsername: this.selectedUsername }
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Forgot your password?\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs8: "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { id: "signIn", type: "submit", block: "" } },
                    [_vm._v("Sign In")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }